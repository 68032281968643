import React, { Component } from "react";
import { compose } from "recompose";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification
} from "../Session";
import { withFirebase } from "../Firebase";

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
  },
  dense: {
    marginTop: 19,
  },
  textFieldSmall: {
    paddingRight: theme.spacing(1)
  },
  paper: {
    marginBottom: 20
  },
});

const AccountDetailsForm = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div>
        <AccountDetails authUser={authUser} />
      </div>
    )}
  </AuthUserContext.Consumer>
);

class AccountDetailsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      changed: false,
      ...this.props.authUser
    };
  }

  componentDidMount() {
    // this.fetchSignInMethods();
    this.setState({ changed: false });
  }

  onSubmit = event => {
    const { callbox_number, callbox_phone, dtmf, user_phone } = this.state;


    // Update user in Firebase
    this.props.firebase.user(this.props.authUser.uid).set(
      {
        callbox_number,
        callbox_phone,
        dtmf,
        user_phone
      },
      { merge: true }
    ).then((result) => {
      // Some success message
      this.setState({ changed: false });
    })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ changed: true });
  };

  render() {
    const { error, callbox_number, callbox_phone, dtmf, user_phone, twilio_phone } = this.state;
    const { classes } = this.props;

    /* #props.authUser.
    callbox_phone - Phone number of the callbox
    callbox_number - Number to dial on callbox
    dtmf - dial tone to send to unlock the door

    user_phone - The user's phone number

    twilio_phone - Assigned twilio phone number
    */
    return (
      <Paper className={classes.paper}>
        <form onSubmit={this.onSubmit} noValidate autoComplete="off">
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <Typography variant="h6" >
                Settings
            </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="user_phone"
                label="Your phone number"
                value={user_phone}
                fullWidth
                onChange={this.onChange}
                helperText="Your personal cell phone number (eg: 2061231234)"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="callbox_phone"
                label="Callbox phone"
                fullWidth
                value={callbox_phone}
                onChange={this.onChange}
                helperText="The phone number of your callbox (eg: 2061231234)"
                margin="normal"
              />
            </Grid>
            <Grid item xs={6} className={classes.textFieldSmall}>
              <TextField
                name="callbox_number"
                label="Callbox number"
                fullWidth
                value={callbox_number}
                onChange={this.onChange}
                helperText="The number to dial for your apartment (eg: 012)"
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="dtmf"
                label="DTMF"
                fullWidth
                value={dtmf}
                onChange={this.onChange}
                helperText="The dial tone to send to unlock your door."
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="callbox_phone"
                label="Your assigned Doorman phone number:"
                disabled
                fullWidth
                value={twilio_phone}
                helperText="Give this to your apartment manager."
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={!this.state.changed}
                type="submit"
                variant="contained"
                color="primary"
              >
                Save Settings
              </Button>
            </Grid>

            {error && error.message}
          </Grid>
        </form>
      </Paper>
    );
  }
}

const AccountDetails = compose(
  withFirebase,
  withStyles(styles)
)(AccountDetailsBase);

const condition = authUser => !!authUser;

export default compose(
  withStyles(styles),
  withEmailVerification,
  withAuthorization(condition)
)(AccountDetailsForm);
