import React from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DomainIcon from '@material-ui/icons/DomainTwoTone';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import VpnKey from '@material-ui/icons/VpnKey';
import Settings from '@material-ui/icons/Settings';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
          <NavigationNonAuth />
        )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
  <div>
    <AppBar position="fixed">
      <Toolbar>
        <IconButton component={Link} to={ROUTES.KEY_LIST} edge="start" color="inherit" aria-label="key list">
          <VpnKey />
        </IconButton>
        <Typography id="toolbar-title" variant="h6" >
          Doorman
          </Typography>
        <IconButton component={Link} to={ROUTES.ACCOUNT} edge="start" color="inherit" aria-label="account">
          <Settings />
        </IconButton>
        {authUser.roles && !!authUser.roles[ROLES.ADMIN] && (
          <Button component={Link} to={ROUTES.ADMIN} color="inherit">Admin</Button>
        )}
        <SignOutButton />
      </Toolbar>
    </AppBar>
    <Fab id="add-key" component={Link} to={ROUTES.KEY_CREATE} color="secondary" aria-label="add">
          <AddIcon />
        </Fab>
  </div>
);

const NavigationNonAuth = () => (
  <div>
    <AppBar position="fixed">
      <Toolbar>
        <IconButton component={Link} to={ROUTES.LANDING} edge="start" color="inherit" aria-label="menu">
          <DomainIcon />
        </IconButton>
        <Typography id="toolbar-title" variant="h6" >
          Doorman
          </Typography>
        <Button component={Link} to={ROUTES.SIGN_IN} color="inherit">Login</Button>
      </Toolbar>
    </AppBar>
  </div>
);

export default Navigation;
