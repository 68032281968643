import app, { firestore } from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import "firebase/performance";

const prodConfig = {
    apiKey: "AIzaSyCtAQJVn7ZIisa796adPQbM5sQs_NGpmMk",
    authDomain: "doorman-c2911.firebaseapp.com",
    databaseURL: "https://doorman-c2911.firebaseio.com",
    projectId: "doorman-c2911",
    storageBucket: "doorman-c2911.appspot.com",
    messagingSenderId: "717005027353",
    appId: "1:717005027353:web:87f3819944103ef4",
    redirectURL: "https://doorman.vip"
};

const devConfig = {
    apiKey: "AIzaSyDWgrmRbvl7rhTcfSafPv9zUHGPpYRao-I",
    authDomain: "doorman-dev-e8c72.firebaseapp.com",
    databaseURL: "https://doorman-dev-e8c72.firebaseio.com",
    projectId: "doorman-dev-e8c72",
    storageBucket: "doorman-dev-e8c72.appspot.com",
    messagingSenderId: "272147318212",
    appId: "1:272147318212:web:51fbcdfcd8e8d66d",
    redirectURL: "http://localhost:3000"
};

const config =
(window.location.protocol + '//' + window.location.hostname) === prodConfig.redirectURL ? prodConfig : devConfig;

class Firebase {
    constructor() {
        app.initializeApp(config);
        
        /* Helper */
        
        this.fieldValue = app.firestore.FieldValue;
        this.emailAuthProvider = app.auth.EmailAuthProvider;
        
        /* Firebase APIs */
        
        this.auth = app.auth();
        this.db = app.firestore();
        this.perf = app.performance();

        /* Social Sign In Method Provider */

        this.googleProvider = new app.auth.GoogleAuthProvider();
        this.facebookProvider = new app.auth.FacebookAuthProvider();
        this.twitterProvider = new app.auth.TwitterAuthProvider();
    }

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignInWithGoogle = () =>
        this.auth.signInWithPopup(this.googleProvider);

    doSignInWithFacebook = () =>
        this.auth.signInWithPopup(this.facebookProvider);

    doSignInWithTwitter = () =>
        this.auth.signInWithPopup(this.twitterProvider);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doSendEmailVerification = () =>
        this.auth.currentUser.sendEmailVerification({
            url: config.redirectURL,
        });

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    // *** Merge Auth and DB User API *** //

    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                this.user(authUser.uid)
                    .get()
                    .then(snapshot => {
                        const dbUser = snapshot.data();

                        // default empty roles
                        if (dbUser && !dbUser.roles) {
                            dbUser.roles = {};
                        }

                        // merge auth and db user
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            emailVerified: authUser.emailVerified,
                            providerData: authUser.providerData,
                            ...dbUser,
                        };

                        next(authUser);
                    });
            } else {
                fallback();
            }
        });

    // *** User API ***

    user = uid => this.db.doc(`users/${uid}`);

    users = () => this.db.collection('users');

    // *** Key API ***
    key = (uid, keyid) => this.db.doc(`users/${uid}/keys/${keyid}`)

    keys = uid => this.db.collection(`users/${uid}/keys`)

    doCreateKey = (uid, data) => this.db.collection(`users/${uid}/keys`).add(data)
    doUpdateKey = (uid, keyid, data) => this.db.doc(`users/${uid}/keys/${keyid}`).set(data)
    doDeleteKey = (uid, keyid) => this.db.doc(`users/${uid}/keys/${keyid}`).delete()

    // *** Log API ***
    doArrived = (callbox_number, twilio_number, data) => 
        this.db.collection(`logs/${callbox_number}/${twilio_number}`).add(data)
    
    // *** Message API ***

    message = uid => this.db.doc(`messages/${uid}`);

    messages = () => this.db.collection('messages');

    // *** Database Helpser ***

    timestamp = () => firestore.Timestamp.now();
    timestampFromDate = (timestamp) => firestore.Timestamp.fromDate(timestamp);

    // *** Performance traces helper

    trace = (traceName) => this.perf.trace(traceName)
}

export default Firebase;