import 'date-fns';
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import DateFnsUtils from '@date-io/date-fns';
import * as ROUTES from '../../constants/routes';

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification
} from "../Session";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { withFirebase } from "../Firebase";

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
  },
  dense: {
    marginTop: 19,
  },
  textFieldSmall: {
    paddingRight: theme.spacing(1)
  },
  paper: {
    marginBottom: 20
  },
});

const INITIAL_STATE = {
  name: '',
  expiry: new Date(),
  forever: false,
  error: null,
  edit: false,
};

const MAX_DATE = new Date("9999-12-31T23:59:59Z")

const CreateKeyPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div>
        <CreateKey authUser={authUser} />
      </div>
    )}
  </AuthUserContext.Consumer>
);

class CreateKeyBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      link: null,
      ...INITIAL_STATE,
      ...this.props.authUser,
      ...this.props.editKey
    };
  }

  componentDidMount() {
    // this.fetchSignInMethods();
    this.setState({ changed: false });
  }

  onSubmit = event => {
    const { name, expiry, forever } = this.state;

    //TODO - allow edit link

    // Create a new key
    this.doCreateKey(name, expiry, forever)

    event.preventDefault();
  };

  doCreateKey = (name, expiry, forever) => {
    this.props.firebase.doCreateKey(this.props.authUser.uid, {
      name: name,
      expiry: (forever) ? this.props.firebase.timestampFromDate(MAX_DATE) :
        this.props.firebase.timestampFromDate(expiry),
      forever: forever
    }).then((result) => {
      // Some success message
      this.doCopyLink(result.id)
      this.setState({ ...INITIAL_STATE });
      this.props.history.push(ROUTES.KEY_LIST);
    }).catch(error => {
      this.setState({ error });
    });
  }

  doCopyLink = (keyid) => {
    let link = window.location.origin + `${ROUTES.KEY_BASE}/` + this.props.authUser.uid + '/' + keyid;
    //let linkElement = document.getElementById("copy-link");
    if (typeof navigator.clipboard !== 'undefined') {
      navigator.clipboard.writeText(link)
    } else {
      prompt("Here's the link to your key:", link)
      //linkElement.select();
      //linkElement.execCommand("copy");
    }
    this.setState({ link: link })
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onCheckboxChange = event => {
    this.setState({ [event.target.name]: event.target.checked });
  }

  onDateTimeChange = time => {
    this.setState({ expiry: time });
  }

  render() {
    const { name, expiry, forever, error, edit } = this.state;
    const { classes } = this.props;

    const isInvalid = (
      name === '');

    /* #props.authUser.
    callbox_phone - Phone number of the callbox
    callbox_number - Number to dial on callbox
    dtmf - dial tone to send to unlock the door

    user_phone - The user's phone number

    twilio_phone - Assigned twilio phone number
    */
    return (
      <Paper className={classes.paper}>
        <form onSubmit={this.onSubmit} noValidate autoComplete="off">
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <Typography variant="h6" >
                Create new key
            </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Guest Name"
                value={name}
                fullWidth
                onChange={this.onChange}
                helperText={`I will great them with "Welcome ${name || 'guest'}"`}
                margin="normal"
              />
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  name="forever"
                  checked={forever}
                  onChange={this.onCheckboxChange}
                  color="primary"
                />
              }
              label="Never Expire"
            />
            {!forever && (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    margin="normal"
                    disablePast
                    id="mui-pickers-date"
                    label="Expiry Date"
                    value={expiry}
                    onChange={this.onDateTimeChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <KeyboardTimePicker
                    margin="normal"
                    id="mui-pickers-time"
                    label="Expiry Time"
                    value={expiry}
                    onChange={this.onDateTimeChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            )}
            <Grid item xs={12}>
              <Button
                disabled={isInvalid}
                type="submit"
                variant="contained"
                color="primary"
              >
                {(edit) ? "Save Key" : "Create and Copy Link"}
              </Button>
            </Grid>

            {error && error.message}
          </Grid>
        </form>
      </Paper>
    );
  }
}

const CreateKey = compose(
  withRouter,
  withFirebase,
  withStyles(styles)
)(CreateKeyBase);

const condition = authUser => !!authUser;

export default compose(
  withStyles(styles),
  withEmailVerification,
  withAuthorization(condition)
)(CreateKeyPage);
