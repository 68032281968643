import React, { Component } from "react";
import PropTypes from 'prop-types';
import { compose } from "recompose";

import { withFirebase } from "../Firebase";

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  arrivingProgress: {
    position: 'relative',
    top: 7,
    left: 10,
  }
});

const ERROR = {
  DOES_NOT_EXSIST: "This key has been deleted or does not exist."
}

class KeyItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      arriving: false,
      arrived: false,
      user: null,
      activeStep: 0,
      ...props.location.state
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    if (this.state.user) {
      return;
    }

    // uid : this.props.match.params.uid,
    // keyid : this.props.match.params.keyid,

    this.setState({ loading: true });

    Promise.all([
      new Promise((resolve, reject) => {
        this.unsubscribe = this.props.firebase
          .user(this.props.match.params.uid)
          .onSnapshot(snapshot => {
            if (snapshot.exists) {
              let data = snapshot.data()
              data.entry_instructions.unshift({
                'title': `Dial ${data.callbox_number} on the callbox`,
                'body': "The callbox will ring and I'll buzz you in"
              })
              data.entry_instructions.unshift({
                'title': 'When you arrive',
                'body': 'Press the "I\'m Here" button and follow the instructions'
              })
              this.setState({ user: data });
              this.setState({ steps: this.getSteps() })
              resolve();
            } else {
              reject(ERROR.DOES_NOT_EXSIST)
            }
          });
      }),

      new Promise((resolve, reject) => {
        try {
          this.unsubscribe2 = this.props.firebase
            .key(this.props.match.params.uid, this.props.match.params.keyid)
            .onSnapshot(snapshot => {
              if (snapshot.exists) {
                const data = snapshot.data();
                this.setState({
                  key: { ...data },
                  expired: (data.expiry.toDate() < new Date())
                });
                resolve();
              } else {
                // Key snapshot does not exist
                reject(ERROR.DOES_NOT_EXSIST)
              }
            });
        } catch (error) {
          reject(error)
        }
      })])
      .then(value => {
        this.setState({ loading: false });
      }).catch(error => {
        this.setState({ loading: false, error: error })
      });
  }


  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
    this.unsubscribe2 && this.unsubscribe2();
  }

  handleArrived = () => {
    return this.props.firebase.doArrived(this.state.user.callbox_phone, this.state.user.twilio_phone, {
      dtmf: this.state.user.dtmf,
      greeting: this.state.key.name,
      user_phone: this.state.user.user_phone,
      key: this.props.firebase.key(this.props.match.params.uid, this.props.match.params.keyid),
      timestamp: this.props.firebase.timestamp(),
    })
    /*data {
      dtmf
      greeting
      key (ref)
      timestamp
      user_phone

    }*/
  };

  getSteps = () => {
    var steps = [];
    Object.keys(this.state.user.entry_instructions).forEach((key) => {
      steps.push(this.state.user.entry_instructions[key].title)
    });
    return steps;
  }

  getStepContent = (step) => {
    if (this.state.user.entry_instructions[step]) {
      return this.state.user.entry_instructions[step].body
    } else {
      return 'Unknown step';
    }
  }

  handleNext() {
    if (this.state.activeStep === 0) {
      this.setState({ arriving: true });
      this.handleArrived().then(ref => {
        this.setState({ arriving: false, arrived: true, activeStep: this.state.activeStep + 1 });
      })
    } else {
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
  }

  handleBack() {
    this.setState({ activeStep: this.state.activeStep - 1 });
  }

  handleReset() {
    this.setState({ activeStep: 0 });
  }

  render() {
    //TODO: add arrived
    const { key, loading, steps, activeStep, arriving, error, expired } = this.state;
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        {loading && (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <CircularProgress className={classes.progress} />
          </Grid>
        )}
        {key && (
          <div>
            {!expired && (
              <div>
                <Typography variant="h4" >
                  Welcome {key.name}
                </Typography>
                {!key.forever && (
                  <Typography>
                    <strong>Your key expires on:</strong> {key.expiry.toDate().toLocaleDateString("en-US")}
                  </Typography>
                )}
              </div>
            )}
          </div>
        )}
        {key && !expired && steps && (
          <div>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    <Typography>{this.getStepContent(index)}</Typography>
                    <div className={classes.actionsContainer}>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={this.handleBack}
                          className={classes.button}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleNext}
                          disabled={arriving}
                        >
                          {activeStep === 0 ? "I'm Here" : activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                        {arriving && <CircularProgress size={25} className={classes.arrivingProgress} />}
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} className={classes.resetContainer}>
                <Typography>You did it <span aria-label="hooray" role="img">🎉</span> - Nothing left to do but party!</Typography>
                <Button onClick={this.handleReset} className={classes.button}>
                  Reset
          </Button>
              </Paper>
            )}
          </div>
        )}
        {expired && (
          <Typography variant="h5">
            This key expired on: {key.expiry.toDate().toLocaleDateString("en-US")}
          </Typography>
        )}
        {error && (
          <Typography>
            {error}
          </Typography>
        )}
      </Paper>
    );
  }
}

KeyItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withFirebase
)(KeyItem);
