import React from "react";

import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: JSON.parse(localStorage.getItem("authUser"))
      };
    }

    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
          localStorage.setItem("authUser", JSON.stringify(authUser));
          this.setState({ authUser });
          // Watch for updates to use details and save them locally
          this.detailsListener = this.props.firebase
            .user(authUser.uid)
            .onSnapshot(userUpdate => {
              //localStorage.setItem("authUser", JSON.stringify(authUser));
              //this.setState({ authUser });
              let userDetails = {...authUser, ...userUpdate.data()}
              localStorage.setItem("authUser", JSON.stringify(userDetails));
              this.setState({ authUser: userDetails});
            });
        },
        () => {
          localStorage.removeItem("authUser");
          this.setState({ authUser: null });
        }
      );
    }

    componentWillUnmount() {
      this.listener();
      this.detailsListener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
