import React, { Component } from 'react';
import { compose } from "recompose";
import { withRouter } from 'react-router-dom';

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification
} from "../Session";

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import DeleteIcon from '@material-ui/icons/Delete';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
});

const UserKeysPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div>
        <UserKeys authUser={authUser} />
      </div>
    )}
  </AuthUserContext.Consumer>
);

class UserKeysBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      activeTab: 0,
      keys: [],
      expiredKeys: [],
      ...this.props.authUser
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .keys(this.props.authUser.uid)
      .onSnapshot(snapshot => {
        let keys = [];

        snapshot.forEach(doc =>
          keys.push({ ...doc.data(), keyid: doc.id, expired: (doc.data().expiry.toDate() < new Date()) }),
        );

        this.setState({
          keys,
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleCopyKeyLink = event => {
    let link = window.location.origin + `${ROUTES.KEY_BASE}/` + this.props.authUser.uid + '/' + event.target.dataset.id;
    //let linkElement = document.getElementById("copy-link");
    if (typeof navigator.clipboard !== 'undefined') {
      navigator.clipboard.writeText(link)
    } else {
      prompt("Here's the link to your key:", link)
      //linkElement.select();
      //linkElement.execCommand("copy");
    }
  };

  handleOpenKey = (keyid) => {
    this.props.history.push(ROUTES.KEY_BASE + '/' + this.props.authUser.uid + '/' + keyid);
  }

  handleDeleteKey = (keyid) => {

    this.props.firebase.doDeleteKey(this.props.authUser.uid, keyid).then(result => {
      let updatedKeys = [...this.state.keys]
      const index = updatedKeys.findIndex(key => key.keyid === keyid)
      if (index !== -1) {
        updatedKeys.splice(index, 1);
        this.setState({ keys: updatedKeys });
      }
    }).catch(error => {
      this.setState({ error: error })
    })
  };

  handleTabChange = (event, value) => {
    this.setState({ activeTab: value });
  };


  render() {
    const { keys, loading, activeTab } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <input id="copy-link" type="text" style={{ display: "none" }}></input>
        <Typography className={classes.header} component="h1" variant="h5" >
          Manage Keys
        </Typography>
        <Paper>
          <Tabs
            value={activeTab}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Active" />
            <Tab label="Expired" />
          </Tabs>
          {loading && (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <CircularProgress className={classes.progress} />
            </Grid>
          )}
          {!loading && keys && (
            <List dense={(!activeTab) ? true : false} className={classes.root} >
              {keys.map(key => (
                <div key={key.keyid}>
                  {((!key.expired && !activeTab) || (!!key.expired && !!activeTab)) && (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          {(key.expired)? <LinkOffIcon/> :<LinkIcon data-id={key.keyid} onClick={this.handleCopyKeyLink} />}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        onClick={() => this.handleOpenKey(key.keyid)}
                        primary={key.name}
                        data-id={key.keyid}
                        secondary={(!activeTab) ? ((key.forever) ? 'No expiery' : key.expiry.toDate().toLocaleDateString("en-US")) : null}
                      />
                      <ListItemSecondaryAction>
                        <IconButton value={key.keyid} onClick={() => this.handleDeleteKey(key.keyid)} edge="end" aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                </div>
              ))}
            </List>
          )}
        </Paper>
      </div>
    );
  }
}

const UserKeys = compose(
  withFirebase,
  withRouter,
  withStyles(styles),
)(UserKeysBase);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(UserKeysPage);
