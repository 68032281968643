import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from "recompose";

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const PasswordForgetPage = () => (
  <div>
    <h1>PasswordForget</h1>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
  },
  resetPasswordButton: {
    Margin: theme.spacing(1)
  },
  dense: {
    marginTop: 19,
  },
  emailInput: {
    marginBottom: 10
  }
});


class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;
    const { classes } = this.props;

    const isInvalid = email === '';

    return (
      <Paper>
        <form onSubmit={this.onSubmit}>
          <Grid container className={classes.container}>
            <Grid item xs={12}  className={classes.emailInput}>
              <TextField
                name="email"
                value={this.state.email}
                onChange={this.onChange}
                fullWidth
                type="text"
                placeholder="Email Address"
              />
            </Grid>
            <Grid item xs={12}>
              <Button 
                disabled={isInvalid} 
                className={classes.resetPasswordButton} 
                type="submit" 
                variant="contained" 
                color="primary"
              >
                Reset Password
              </Button>
            </Grid>
            {error && (
            <Grid item xs={12}>
              <Typography>{error.message}</Typography>
            </Grid>
            )}
          </Grid>
        </form>
      </Paper>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = compose(
  withFirebase,
  withStyles(styles)
)(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
